import React from "react";
import "./Card.css";

const CardComponent = (props) => {
  const { imageSrc, text, alignLeft } = props;

  return (
    <div
      className={`card ${alignLeft ? "align-left" : "align-right"}`}
      style={props.cardImageStyle}
    >
      <div className={props.style ? "cardImg" : "card-image"}>
        {" "}
        <img
          src={imageSrc}
          alt="Card"
          className={props.style ? "cardImg" : "card-image"}
        />
      </div>
      <p className="card-textstyle" style={props.textStyle}>
        {text}
      </p>
    </div>
  );
};

export default CardComponent;
