import React from "react";
import "./Product.css";
import CardComponent from "../../components/card/Card";
import MainHead from "../../components/MainHeading/MainHeading";
import Footer from "../../components/footer/Footer";
import img1 from "../../../assets/images/Prod1.png";
import data from "../../../assets/images/sec.png";
import care from "../../../assets/images/care.png";
import priv from "../../../assets/images/privacy.png";
import BackGround from "../../components/backGround/BackGround";
import demo from "../../../assets/images/btn.png";
import newdata from "../../../assets/images/newdata.png";
import newcare from "../../../assets/images/newcare.png";
import {
  isDesktopOrLaptop,
  isSmallMobile,
  ismobile,
  istab,
} from "../../utils/Constants";
import Bullets from "../../components/bullet point/bullet";

const Product = () => {
  console.log("====================================");
  console.log(window?.innerWidth);
  console.log("====================================");
  return (
    <div className="product-main">
      <div className="product-First">
        <div className="TextContain">
          {/* <div
            className="product-Head"
            style={{
              width: isDesktopOrLaptop && istab ? 400 : 300,
              paddingLeft: isDesktopOrLaptop ? 32 : "",
            }}
          > */}
          <h1 className="product-Head">
            Prescription Made <br />
            Easy
          </h1>
          {/* </div> */}
          <p
            id="Desc"
            // style={{
            //   paddingLeft: isDesktopOrLaptop ? 32 : "",
            // }}
          >
            Empowering Doctors to deliver Exceptional Patient Care!
          </p>
        </div>
        <div className="img-Container">
          <img src={img1} alt="img" className="img-Container" />
        </div>
      </div>
      <MainHead head={"Efficient Health Data Sharing for Doctors"} />
      <div className="CardContain">
        <CardComponent
          bullet={true}
          style={true}
          // textStyle={{
          //   fontSize: isDesktopOrLaptop
          //     ? 20
          //     : istab
          //     ? 18
          //     : ismobile
          //     ? 16
          //     : isSmallMobile
          //     ? 14
          //     : 4,
          // }}
          // style={{
          //   width: isDesktopOrLaptop ? 390 : 300,
          //   height: isDesktopOrLaptop ? 399 : 322,
          // }}
          imageSrc={newdata}
          text={
            <ol
              style={{ display: "flex", flexDirection: "column", gap: "1rem" }}
            >
              <li>Streamlines patient information exchange</li>
              <li>Swift access to up-to-date records</li>
              <li>Enhanced collaboration among healthcare professionals.</li>

              {/* <div className="dots">
                <Bullets />
                <p>Streamlines patient information exchange</p>
              </div>
              <div className="dots">
                <Bullets />
                <p>Swift access to up-to-date records</p>
              </div>
              <div className="dots">
                <div>
                  <Bullets />
                </div>

                <p>
                  Enhanced collaboration among healthcare
                  <br /> professionals.
                </p>
              </div> */}
            </ol>
          }
          alignLeft
        />
      </div>
      <MainHead head={"Enhanced Patient Engagement"} />
      <div className="CardContain">
        <CardComponent
          style={true}
          // textStyle={{
          //   fontSize: isDesktopOrLaptop
          //     ? 20
          //     : istab
          //     ? 18
          //     : ismobile
          //     ? 16
          //     : isSmallMobile
          //     ? 14
          //     : 4,
          //   lineHeight: isDesktopOrLaptop ? 2 : 1.5,
          //   paddingBottom: isDesktopOrLaptop ? 4 : 32,
          // }}
          // style={{
          //   width: isDesktopOrLaptop ? 390 : 300,
          //   height: isDesktopOrLaptop ? 399 : 322,
          // }}
          imageSrc={newcare}
          text={
            <ol
              style={{ display: "flex", flexDirection: "column", gap: "1rem" }}
            >
              <li>Stay connected with patients beyond the clinic</li>
              <li>Secure messaging, health articles, appointment reminders.</li>
              <li>Enhance wellness journey</li>

              {/* <div className="dots">
                <Bullets />
                <p>Stay connected with patients beyond the clinic.</p>
              </div>
              <div className="dots">
                <Bullets />
                <p>Secure messaging, health articles, appointment reminders.</p>
              </div>
              <div className="dots">
                <div>
                  <Bullets />
                </div>
                <p>Enhance wellness journey</p>
              </div> */}
            </ol>
          }
        />
      </div>
      <MainHead head={"Privacy & Security"} />
      <div className="CardContain">
        <CardComponent
          style={true}
          // textStyle={{
          //   fontSize: isDesktopOrLaptop
          //     ? 20
          //     : istab
          //     ? 18
          //     : ismobile
          //     ? 16
          //     : isSmallMobile
          //     ? 14
          //     : 4,
          //   lineHeight: isDesktopOrLaptop ? 2 : 1.5,
          //   paddingBottom: isDesktopOrLaptop ? 4 : 32,
          // }}
          // style={{
          //   width: isDesktopOrLaptop ? 390 : 300,
          //   height: isDesktopOrLaptop ? 399 : 322,
          // }}
          imageSrc={priv}
          text={
            "Your safety and privacy are our top priorities. We have implemented robust security measures and privacy protocols to protect your information."
          }
          alignLeft
        />
      </div>
      <div>
        <BackGround
          to={"/contact"}
          text={"Get the app right now!.."}
          src={demo}
          // imgStyle={{
          //   width: isDesktopOrLaptop ? 300 : istab ? 250 : ismobile ? 170 : 75,
          //   height: isDesktopOrLaptop ? 75 : istab ? 45 : ismobile ? 40 : 15,
          // }}
        />
      </div>
      <div>
        <Footer />
      </div>
    </div>
  );
};

export default Product;
