import React from "react";
import "./selection.css";
const SelectionTab = (props) => {
  const handleClick = () => {
    if (props.link) {
      window.open(props.link, "_blank");
    }
  };
  return (
    <button
      onClick={props.link ? handleClick : props.onClick}
      className="btnstyle"
      style={props.style}
    >
      {props.text}
    </button>
  );
};
export default SelectionTab;
