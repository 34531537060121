import React from "react";
import Footer from "../../components/footer/Footer";
import "./faq.css";
import FAQList from "../../components/FaqS/Q&S";
import { faqData } from "../../utils/Constants";
import { isDesktopOrLaptop } from "../../utils/Constants";

const Faq = () => {
  return (
    <div className="privacy-main">
      <div
      // style={{
      //   textAlign: "center",
      //   marginTop: isDesktopOrLaptop ? 168 : 100,
      //   marginLeft: isDesktopOrLaptop ? 100 : 24,
      //   marginRight: isDesktopOrLaptop ? 100 : 24,
      // }}
      >
        <div className="privacy-head">
          <b className="Hedaerdes">Frequently Asked Questions</b>
        </div>
        <div className="faqData">
          <FAQList faqData={faqData} />
          {/* <p style={{ textAlign: "center", fontSize: 32, color: "#4ba5fa" }}>
          Coming Soon .....
        </p> */}
        </div>
      </div>
      <div style={{ marginTop: "8%" }}>
        <Footer />
      </div>
    </div>
  );
};

export default Faq;
