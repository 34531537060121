import React, { useRef, useState, useEffect } from "react";
import "./Header.css"; // Import your CSS file
import logo from "../../../assets/images/logo (1).png";
import { FaBars, FaTimes } from "react-icons/fa";
import { Link } from "react-router-dom";
import TN from "../../../assets/images/inst.png";
import { isDesktopOrLaptop } from "../../utils/Constants";

const Header = (props) => {
  const [aboutOpen, setAboutOpen] = useState(false);
  const [isBlinking, setIsBlinking] = useState(true);
  const [activeButton, setActiveButton] = useState("Home");
  const navRef = useRef();
  const shownavbar = () => {
    navRef.current.classList.toggle("responsive_nav");
  };
  const handleButtonClick = (buttonName) => {
    setActiveButton(buttonName);
  };
  const HandleAbout = (event) => {
    setAboutOpen(!aboutOpen);
  };
  useEffect(() => {
    const blink = setInterval(() => {
      setIsBlinking((prev) => !prev);
    }, 10000);
    return () => clearInterval(blink);
  }, []);
  return (
    <div className="hattai-main">
      <header>
        <img src={logo} alt="healthattai" className="header-image" />
        {/* <div> */}
        <nav ref={navRef}>
          <a href="/" onClick={() => handleButtonClick("Home")}>
            Home
          </a>
          <a href="/product" onClick={() => handleButtonClick("Product")}>
            Product
          </a>
          <div className="dropdown">
            <a className="about" onClick={HandleAbout}>
              About
              <i class="fa-solid fa-caret-down"></i>
            </a>
            {isDesktopOrLaptop ? (
              <>
                {aboutOpen ? (
                  <div className="down-content">
                    <a
                      href="/about"
                      onClick={() => {
                        handleButtonClick("About");
                        HandleAbout();
                      }}
                    >
                      <i
                        class="fa-solid fa-user-group"
                        style={{ marginRight: "4px" }}
                      ></i>{" "}
                      Our Story
                    </a>
                    <a
                      href="/tips"
                      onClick={() => {
                        handleButtonClick("tips");
                        HandleAbout();
                      }}
                    >
                      <i
                        class="fa-solid fa-heart"
                        style={{ marginRight: "4px" }}
                      ></i>{" "}
                      Health Tips
                    </a>
                  </div>
                ) : (
                  <div className="down-content">
                    <a
                      href="/about"
                      onClick={() => {
                        handleButtonClick("About");
                        HandleAbout();
                      }}
                    >
                      <i
                        class="fa-solid fa-user-group"
                        style={{ marginRight: "4px" }}
                      ></i>{" "}
                      Our Story
                    </a>
                    <a
                      href="/tips"
                      onClick={() => {
                        handleButtonClick("tips");
                        HandleAbout();
                      }}
                    >
                      <i
                        class="fa-solid fa-heart"
                        style={{ marginRight: "4px" }}
                      ></i>{" "}
                      Health Tips
                    </a>
                  </div>
                )}
              </>
            ) : (
              <>
                {aboutOpen && (
                  <div className="down-content">
                    <a
                      href="/about"
                      onClick={() => {
                        handleButtonClick("About");
                        HandleAbout();
                      }}
                    >
                      <i
                        class="fa-solid fa-user-group"
                        style={{ marginRight: "4px" }}
                      ></i>{" "}
                      Our Story
                    </a>
                    <a
                      href="/tips"
                      onClick={() => {
                        handleButtonClick("tips");
                        HandleAbout();
                      }}
                    >
                      <i
                        class="fa-solid fa-heart"
                        style={{ marginRight: "4px" }}
                      ></i>{" "}
                      Health Tips
                    </a>
                  </div>
                )}
              </>
            )}
          </div>

          <a href="/contact" onClick={() => handleButtonClick("Contact")}>
            Contact
          </a>

          <a
            href="/abha"
            className="abha"
            onClick={() => handleButtonClick("Abha")}
          >
            What's ABHA
          </a>
          <button className="nav-btn nav-close-btn" onClick={shownavbar}>
            <FaTimes />
          </button>
        </nav>
        <button className="nav-btn" onClick={shownavbar}>
          <FaBars />
        </button>
        {/* </div> */}
      </header>
      <div className="invest">
        <p style={{ fontSize: 12 }}>Invested by</p>

        <Link to="/">
          <img
            src={TN}
            alt={"StartupTN"}
            style={{ width: 100, height: 32 }}
            className={`image ${isBlinking ? "blinking" : ""}`}
            onClick={props.onClick}
          />
        </Link>
      </div>
    </div>
  );
};

export default Header;
