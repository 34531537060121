import React, { useState } from "react";
import "./Q&S.css";

const FAQItem = (props) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleAnswer = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className={`faq-item ${isOpen ? "faq-item-open" : "faq-item-close"}`}>
      <div
        className={`faq-question ${
          isOpen ? "faq-question-open" : "faq-question-close"
        }`}
        onClick={toggleAnswer}
      >
        {props.question}
        <span className={`icon1 ${isOpen ? "open" : "closed"}`}>
          <i
            className={`fas ${isOpen ? "fa-chevron-up" : "fa-chevron-left"}`}
          ></i>
        </span>
      </div>
      {isOpen && (
        <div className="after-open">
          <hr
            className={`horizontal-line ${
              isOpen ? "horizontal-line-open" : "horizontal-line-close"
            }`}
          />
          <div
            className={`faq-answer ${
              isOpen ? "faq-answer-open" : "faq-answer-close"
            }`}
          >
            {props.answer}
          </div>
        </div>
      )}
    </div>
  );
};

const FAQList = ({ faqData }) => {
  return (
    <div className="faq-list">
      {faqData.map((faq, index) => (
        <FAQItem key={index} question={faq.question} answer={faq.answer} />
      ))}
    </div>
  );
};

export default FAQList;
