import Footer from "../../components/footer/Footer";
import Header from "../../components/Header/Header";
import { isDesktopOrLaptop } from "../../utils/Constants";
import "./Term.css";

const Terms = () => {
  return (
    <div className="privacy-main">
      <div>
        {/* <div>
                <Header/>
            </div> */}
        <div className="privacy-head">
          <b className="Hedaerdes">Terms & Conditions</b>
        </div>
        <div className="privacyContainer">
          <p className="THead">
            This platform{" "}
            <a href="#" style={{ color: "#4ba5fa" }}>
              [www.healthattai.com]
            </a>{" "}
            , is owned and operated by Destratum Solutions Private Limited. We
            urge you to carefully review these Terms of Service before accessing
            or utilizing our platform. The most up-to-date version of the Terms
            of Service can always be found on this page.
          </p>
          <p className="THead">
            Destratum Solutions Private Limited is the provider of Health-Attai,
            a comprehensive healthcare management platform that includes
            information, tools, and services designed for your use. Access and
            usage of this platform are subject to your acceptance of all the
            terms, conditions, policies, and notices detailed here. By accessing
            any part of the platform or utilizing its services, you signify your
            agreement to comply with this Agreement, our Privacy Policy, and
            other applicable rules, guidelines, policies, terms, and conditions
            related to the services and modules provided through Health-Attai.
            These collectively form the “Terms”.
          </p>
          <p className="THead">
            If you do not consent to all the terms and conditions set forth in
            this agreement, you should refrain from accessing the platform or
            using any of its services.
          </p>
          <p className="SubHead">Acceptance of Terms:</p>
          <p className="THead">
            By using our healthcare software services, you acknowledge that you
            have read, understood, and agree to these Terms and Conditions. If
            you do not agree with any part of these terms, please refrain from
            using our service
          </p>
          <p className="SubHead">Use of Services:</p>
          <p className="THead">
            Our healthcare software is designed to enhance medical operations
            and communication. You agree to use our software for legitimate
            healthcare-related purposes only. You will not engage in any
            activities that compromise the security, privacy, or functionality
            of the software
          </p>
          <p className="SubHead">Privacy and Data Security</p>
          <p className="THead">
            Your use of our healthcare software is governed by our Privacy
            Policy. This policy outlines how we collect, use, store, and protect
            your healthcare data. By using our services, you consent to the
            practices described in the privacy policy{" "}
          </p>
          <p className="SubHead">User Accounts:</p>
          <p className="THead">
            Certain features of our healthcare software may require user
            accounts. You are responsible for maintaining the confidentiality of
            your account credentials and any actions taken under your account.
            You agree to provide accurate and up-to-date information.
          </p>
          <p className="SubHead">User Responsibilities:</p>
          <p className="THead">
            You are solely responsible for the data you input into our
            healthcare software. You warrant that you have the necessary rights
            and permissions to input and manage such data. You understand that
            we provide the software tools, but you are responsible for verifying
            the accuracy and integrity of the data.
          </p>
          <p className="SubHead">Intellectual Property:</p>
          <p className="THead">
            Our healthcare software and its content are protected by
            intellectual property laws. You may not copy, modify,distribute, or
            reproduce any part of our software without our prior written
            consent.
          </p>
          <p className="SubHead">Limitation of Liability:</p>
          <p className="THead">
            Our healthcare software is provided "as is" and without warranties
            of any kind. We do not guarantee the accuracy, reliability, or
            completeness of the information provided by the software. You use
            our services at your own risk.
          </p>
          <p className="SubHead">Changes to Terms:</p>
          <p className="THead">
            We reserve the right to modify or update these Terms and Conditions
            at any time. We will notify you of significant changes through the
            software or via email. Your continued use of our healthcare software
            after the effective date of any modifications constitutes your
            acceptance of the revised terms
          </p>
          <p className="SubHead">Contact Information:</p>
          <p className="THead">
            For questions, concerns, or inquiries about these Terms and
            Conditions or the use of our healthcare software, please contact us
            at{" "}
            <a href="mailto:contact@destratum.com" style={{ color: "#4ba5fa" }}>
              contact@destratum.com
            </a>
          </p>
          <p className="THead">
            Thank you for choosing Destratum Solutions Private Limited as your
            healthcare software partner. We are committed to delivering
            efficient and secure software solutions for the healthcare industry.
          </p>
        </div>
      </div>
      <div style={{ marginTop: "10%" }}>
        <Footer />
      </div>
    </div>
  );
};

export default Terms;
