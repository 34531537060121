import React from "react";
import "./videocard.css";
import SelectionTab from "../selectionTab/selection";

const VideoCard = (props) => {
  return (
    <div className="videocontainer">
      <div className="video1">
        <video width="350" height="200" controls>
          <source src={props.videoSrc} type="video/mp4" />
        </video>
      </div>
      <div className="content">
        <h2 id="tip-topic">{props.topic}</h2>
        <p id="desc">{props.desc}</p>

        <div className="btn">
          <SelectionTab text={"Click me"} link={props.link} />
        </div>
      </div>
    </div>
  );
};
export default VideoCard;
