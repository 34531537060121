import React, { useRef } from "react";
// import Header from '../src/root/components/Header/Header'
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

// import Home from './root/pages/Home/Home'
import Home from "../pages/Home/Home";
import Product from "../pages/Product/Product";
import About from "../pages/About/About";
import Contact from "../pages/Contact/Contact";
import Header from "../components/Header/Header";
import Terms from "../pages/Terms/Terms";
import Privacy from "../pages/Privacy/Privacy";
import Faq from "../pages/FAQ/faq";
import Abha from "../pages/Abha/Abha";
import Health_Tips from "../pages/Health/tips";

const Navigation = () => {
  const achievementRef = useRef();
  const scrollToAchievement = () => {
    if (achievementRef.current) {
      achievementRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };
  return (
    <Router>
      <div>
        <Header onClick={scrollToAchievement} />
      </div>
      <Routes>
        <Route path="/" element={<Home refer={achievementRef} />} />
        <Route path="/product" element={<Product />} />
        <Route path="/about" element={<About />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/privacy-policy" element={<Privacy />} />
        <Route path="/terms-conditions" element={<Terms />} />
        <Route path="/faqs" element={<Faq />} />
        <Route path="/abha" element={<Abha />} />
        <Route path="/tips" element={<Health_Tips />} />
        <Route path="/" element={<Home />} />
      </Routes>
    </Router>
  );
};

export default Navigation;
