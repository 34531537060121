import React from "react";
import "./Arrow.css";
// import { ArrowIcon } from '../../utils/Constants';

const Arrow = (props) => {
  return (
    <div className="RoundContainer" style={props.style}>
      <div className="RoundArrow">
        <div className="Round">
          <b className="InText">{props.No}</b>
        </div>
        <p className="RoundText">{props.text}</p>
      </div>
    </div>
  );
};

export default Arrow;
