import React from "react";
import "./MainHeading.css";
import { isDesktopOrLaptop } from "../../utils/Constants";

const MainHead = (props) => {
  return (
    <div className="Header-container" ref={props.ref} style={props.style}>
      <p
        className="headerText"
        style={{
          fontSize: isDesktopOrLaptop ? 32 : 24,
          padding: isDesktopOrLaptop ? 24 : 8,
        }}
      >
        {props.head}
      </p>
    </div>
  );
};

export default MainHead;
