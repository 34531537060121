import React from "react";
import "./img.css";
import { Link } from "react-router-dom";

const Image = (props) => {
  return (
    <div
      className={`ImgContainer${props.container ? ` ${props.container}` : ""}`}
      style={{ height: props.containerHeight, width: props.containerWidth }}
    >
      <Link to={props.href}>
        <img
          className="ImgContainer"
          src={props.src}
          alt={props.alt}
          style={props.style}
        />
      </Link>
      <a style={props.textStyle} href={props.href}>
        <p className={props.class}>{props.text}</p>
      </a>
    </div>
  );
};

export default Image;
