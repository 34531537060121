import React from "react";
// import Header from '../src/root/components/Header/Header'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

// import Home from './root/pages/Home/Home'
// import Product from "./root/pages/Product/Product";
// import About from "./root/pages/About/About";
// import Contact from "./root/pages/Contact/Contact";
// import Header from "./root/components/Header/Header";
// import Terms from "./root/pages/Terms/Terms";
// import Privacy from "./root/pages/Privacy/Privacy"
// import Faq from "./root/pages/FAQ/faq";
import Navigation from "./root/Route/routing";





const App = () => {
  return (
  <Navigation/>
  );
};

export default App;