import React from "react";
import "./BackGround.css";
import { Link } from "react-router-dom";

const BackGround = (props) => {
  return (
    <div className="Back">
      <div className="bText">
        <a href={props.textHref} rel="noreferrer" target="_blank">
          {" "}
          <p style={props.style} className="backgroundText">
            {props.text}
          </p>
        </a>
      </div>
      <div className="ba">
        <a href={props.href}>
          {" "}
          <Link
            to={props.to}
            //   className={activeButton === 'Contact' ? 'header-button1 active' : 'header-button1'}
          >
            <img className="ba-image" src={props.src} alt={props.alt}></img>
          </Link>
        </a>
        {/* <a href={props.href1}>
          <img className="ba-image" src={props.src1} alt={props.alt1}></img>
        </a> */}
      </div>
    </div>
  );
};

export default BackGround;
