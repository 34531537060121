import React from "react";
import "./features.css";
import { isDesktopOrLaptop } from "../../utils/Constants";

const FeatureCard = (props) => {
  return (
    <div className="features-container">
      <div className="container">
        <p className="Headc">{props.TextHead}</p>
        <p className="textc">{props.Text}</p>
      </div>
    </div>
  );
};

export default FeatureCard;
