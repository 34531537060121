import React from "react";
import "./HButton.css";
// import logo from '../../assets/images/img1.png'

const HButton = (props) => {
  return (
    <button onClick={props.onClick} className="Button" style={props.style}>
      {props.text}
      <img src={props.src} alt={props.alt} />
    </button>
  );
};

export default HButton;
