import React from "react";
import "./input.css";

const TextArea = (props) => {
  return (
    <div className="input-container">
      <textarea
        style={props.style}
        className="textarea"
        onChange={props.onChange}
        value={props.value}
        placeholder={props.placeholder}
        rows={props.rows}
        cols={props.cols}
      />
    </div>
  );
};
export default TextArea;
