import React from "react";
import "./About.css";
import CardComponent from "../../components/card/Card";
import Footer from "../../components/footer/Footer";
import img1 from "../../../assets/images/Prod1.png";
import BackGround from "../../components/backGround/BackGround";
import MainHead from "../../components/MainHeading/MainHeading";
import inst from "../../../assets/images/inst.png";
import Image from "../../components/Image/Images";
import Aic from "../../../assets/images/AIC.png";
import anna from "../../../assets/images/anna.png";
import dest from "../../../assets/images/dest.png";
import {
  isDesktopOrLaptop,
  isSmallMobile,
  ismobile,
  istab,
} from "../../utils/Constants";

const About = () => {
  return (
    // <div>
    <div className="HeaderContainer">
      <div className="head-first1">
        <h1 className="Hedaerdes">Who We are</h1>
        <p className="head-desc">
          {" "}
          It all started during the corona. People with same goal united
          together to create Destratum Solutions. "Health-Attai" is designed to
          foster a strong bond between healthcare providers and their patients,
          enabling seamless communication, personalized care, and empowering
          individuals to take charge of their wellbeing.
        </p>
      </div>
      <MainHead head={"Vision"} />
      <div className="head-first">
        <p className="head-desc">
          {" "}
          We envision a healthcare landscape where communication between doctor
          and patient is effortless and prescriptions are managed with utmost
          efficiency. We strive to bridge the gap between medical practitioners
          and those seeking care, fostering a harmonious journey towards
          well-being.
        </p>
      </div>
      <MainHead head={"Mission"} />
      <div className="head-first">
        <p className="head-desc">
          At Health-Attai, our core mission is to enhance bond between
          healthcare provider and patient, enabling seamless communication,
          personalized care, and empowering individual to take control of their
          well-being.
        </p>
      </div>
      <MainHead head={"Our Investor"} />
      <div className="head-first">
        <Image src={inst} alt={"inst"} />
      </div>
      <MainHead head={"Our Incubation Support"} />
      <div className="head-first">
        <Image src={anna} alt={"inst"} />
      </div>
      {/* <MainHead head={"Visit Our Site"} />
        <div style={{ marginTop: 48 }}>
          <Image
            src={dest}
            alt={"inst"}
            style={{ height: 182, width: 182 }}
            textStyle={{ textDecorationLine: "none" }}
            href={"http://destratum.com/"}
            class={"textClick"}
            text={"Click Here"}
          />
        </div> */}
      <div>
        <Footer />
      </div>
    </div>
    // </div>
  );
};

export default About;
