import Footer from "../../components/footer/Footer";
import { isDesktopOrLaptop } from "../../utils/Constants";
import "./Privacy.css";

const Privacy = () => {
  return (
    <div className="privacy-main">
      <div
      // style={{
      //   textAlign: "center",
      //   marginTop: isDesktopOrLaptop ? 168 : 100,
      //   marginLeft: isDesktopOrLaptop ? 100 : 24,
      //   marginRight: isDesktopOrLaptop ? 100 : 24,
      // }}
      >
        <div className="privacy-head">
          <b className="Hedaerdes">Privacy Policy</b>
        </div>
        <div className="privacyContainer">
          <p className="SubHead">Effective Date: 15 Aug 2023</p>
          <p className="THead">
            At Health-Attai, we are committed to safeguarding the privacy and
            security of your health data. This Privacy Policy outlines how we
            collect, use, disclose, and protect your health information when you
            use our clinical management system. Please take the time to read and
            understand this policy to ensure you are informed about the
            practices regarding your data.
          </p>
          <p className="SubHead">Information We Collect:</p>
          <p className="PHead">Personal Information:</p>
          <p className="THead">
            We may collect personal information such as your name, contact
            details, date of birth, and other identifiers necessary for
            identification and communication purposes
          </p>
          <p className="PHead">Health Data:</p>
          <p className="THead">
            Health-Attai is designed to store and manage your health records,
            including medical history, test results, prescriptions, and
            treatment plans. We only collect health data that you provide or
            that is shared with us by authorized healthcare providers.
          </p>
          <p className="PHead">Usage Data:</p>
          <p className="THead">
            We gather information about how you use our platform, including log
            data, device information, and browsing activity. This data helps us
            enhance our services and improve user experience.
          </p>
          <p className="SubHead">How We Use Your Information:</p>
          <p className="PHead">Provision of Services:</p>
          <p className="THead">
            We use your health data to provide our clinical management system,
            enabling seamless communication between healthcare providers and
            patients, and to support personalized care.
          </p>
          <p className="PHead">Health Insights:</p>
          <p className="THead">
            With your consent, we may use de-identified and aggregated data to
            generate health insights and analytics. This helps us improve the
            system and contribute to medical research while preserving your
            anonymity.
          </p>
          <p className="PHead">Communication:</p>
          <p className="THead">
            {" "}
            We may use your contact information to send relevant updates,
            appointment reminders, and other notifications related to your
            healthcare.
          </p>
          <p className="SubHead">Data Sharing and Disclosure:</p>
          <p className="PHead">Healthcare Providers:</p>
          <p className="THead">
            Health-Attai facilitates secure data sharing between patients and
            authorized healthcare providers, ensuring seamless collaboration in
            your care
          </p>
          <p className="PHead">Legal Requirements::</p>
          <p className="THead">
            We may disclose your information when required by law, regulation,
            or legal process to protect our rights or respond to a legal request
          </p>
          <p className="PHead">Consent:</p>
          <p className="THead">
            {" "}
            We will seek your explicit consent before sharing your identifiable
            health information for any purposes not mentioned in this Privacy
            Policy.
          </p>
          <p className="PHead">Data Security:</p>
          <p className="THead">
            We implement industry-standard security measures to protect your
            health data from unauthorized access, alteration, disclosure, or
            destruction.
            <p>
              All data transmissions between your device and our platform are
              encrypted to ensure secure communication.
            </p>
          </p>
          <p className="SubHead">Your Choices and Control:</p>
          <p className="PHead">Access and Correction:</p>
          <p className="THead">
            You have the right to access, review, and correct your health data
            stored on Health-Attai. You may also request updates or deletions of
            your informatio
          </p>
          <p className="PHead">Consent Withdrawal:</p>
          <p className="THead">
            You can withdraw your consent for specific data processing
            activities. However, please note that this may impact the
            functionality of our services
          </p>
          <p className="PHead">Third-Party Services:</p>
          <p className="THead">
            Health-Attai may integrate with third-party services to enhance your
            experience. These services may have their privacy policies, and we
            encourage you to review them as we do not control their practices
          </p>
          <p className="PHead">Changes to Privacy Policy:</p>
          <p className="THead">
            We may update this Privacy Policy from time to time to reflect
            changes in our practices or applicable laws. We will notify you of
            any significant changes through the platform or via email For any
            questions or concerns regarding this Privacy Policy or your health
            data, please contact us at{" "}
            <a href="mailto:contact@destratum.com" style={{ color: "#4ba5fa" }}>
              contact@destratum.com
            </a>
          </p>
          <p className="THead" style={{ paddingTop: 24 }}>
            Thank you for trusting Health-Attai with your health information. We
            are committed to continually improving our services and protecting
            your privacy.
          </p>
        </div>
      </div>
      <div style={{ marginTop: "10%" }}>
        <Footer />
      </div>
    </div>
  );
};

export default Privacy;
